html {
  margin: 0;
  padding: 0;
  border: 0; }

body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, nav, section {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline; }

article, aside, dialog, figure, footer, header, hgroup, nav, section {
  display: block; }

body {
  line-height: 1.5;
  background: white; }

table {
  border-collapse: separate;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  float: none !important; }

table, th, td {
  vertical-align: middle; }

blockquote before, blockquote after, q before, q after {
  content: ''; }

a img {
  border: none; }

* {
  box-sizing: border-box;
  outline: 0 none; }

body {
  font: normal 16px/1.6em sans-serif;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-y: scroll; }

.wrapper {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px; }

.page-wrapper {
  max-width: 1140px;
  margin: 100px auto;
  padding: 0 15px; }

.container {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  /* height: 100vh; */ }
  .container:after {
    content: " ";
    display: block;
    clear: both; }

.img-responsive {
  max-width: 100%;
  height: auto; }

.row {
  zoom: 1; }
  .row:before, .row:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .row:after {
    clear: both; }
  @media (min-width: 64em) {
    .row .col-6 {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 64em) {
    .row .col-6:last-child {
      width: 49.15254%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 64em) {
    .row .col-6.last-child {
      width: 49.15254%;
      float: right;
      margin-right: 0; } }

table {
  width: 100%;
  border-collapse: collapse; }

tr:nth-of-type(odd) {
  background: #eee; }

th {
  background: #333;
  color: white;
  font-weight: bold; }

td, th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left; }

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table.responsive, .responsive thead, .responsive tbody, .responsive th, .responsive td, .responsive tr {
    display: block; }
  /* Hide table headers (but not display: none;, for accessibility) */
  .responsive thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  .responsive tr {
    border: 1px solid #ccc; }
  .responsive td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%; }
  .responsive td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap; }
  /*
	Label the data
	*/
  .responsive td:before {
    content: attr(data-table-header); } }

.table-responsive .bootstrap {
  min-height: .01%;
  overflow-x: auto; }

@media screen and (max-width: 767px) {
  .table-responsive.bootstrap {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd; }
  .table-responsive.bootstrap > .table {
    margin-bottom: 0; }
  .table-responsive.bootstrap > .table > thead > tr > th, .table-responsive.bootstrap > .table > tbody > tr > th, .table-responsive.bootstrap > .table > tfoot > tr > th, .table-responsive.bootstrap > .table > thead > tr > td, .table-responsive.bootstrap > .table > tbody > tr > td, .table-responsive.bootstrap > .table > tfoot > tr > td {
    white-space: nowrap; }
  .table-responsive.bootstrap > .table-bordered {
    border: 0; }
  .table-responsive.bootstrap > .table-bordered > thead > tr > th:first-child, .table-responsive.bootstrap > .table-bordered > tbody > tr > th:first-child, .table-responsive.bootstrap > .table-bordered > tfoot > tr > th:first-child, .table-responsive.bootstrap > .table-bordered > thead > tr > td:first-child, .table-responsive.bootstrap > .table-bordered > tbody > tr > td:first-child, .table-responsive.bootstrap > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0; }
  .table-responsive.bootstrap > .table-bordered > thead > tr > th:last-child, .table-responsive.bootstrap > .table-bordered > tbody > tr > th:last-child, .table-responsive.bootstrap > .table-bordered > tfoot > tr > th:last-child, .table-responsive.bootstrap > .table-bordered > thead > tr > td:last-child, .table-responsive.bootstrap > .table-bordered > tbody > tr > td:last-child, .table-responsive.bootstrap > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0; }
  .table-responsive.bootstrap > .table-bordered > tbody > tr:last-child > th, .table-responsive.bootstrap > .table-bordered > tfoot > tr:last-child > th, .table-responsive.bootstrap > .table-bordered > tbody > tr:last-child > td, .table-responsive.bootstrap > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0; } }

form input[type="text"], form input[type="number"], form input[type="email"], form input[type="tel"], form textarea, form .tagsinput {
  padding: 10px;
  background: #f7f7f7;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  font-size: 0.9em;
  font-family: inherit;
  width: 100%;
  margin: 0 0 20px;
  color: #666;
  vertical-align: middle; }
form textarea {
  line-height: 1.8em; }
form .row {
  display: block;
  zoom: 1; }
  form .row:before, form .row:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  form .row:after {
    clear: both; }
  @media (min-width: 50em) {
    form .row .width:last-child {
      padding-left: 20px; }
    form .row .width-25 {
      width: 25%;
      float: left; }
    form .row .width-30 {
      width: 30%;
      float: left; }
    form .row .width-40 {
      width: 40%;
      float: left; }
    form .row .width-50 {
      width: 50%;
      float: left; }
    form .row .width-60 {
      width: 60%;
      float: left; }
    form .row .width-70 {
      width: 70%;
      float: left; }
    form .row .width-75 {
      width: 75%;
      float: left; } }

.custom-select-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 42px;
  padding: 5px 8px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  background-image: linear-gradient(to bottom, #ffffff 0%, #f6f6f6 100%);
  margin: 0 0 10px; }
  .custom-select-wrapper:before {
    content: "";
    position: absolute;
    border-collapse: separate;
    border-top-color: #58585a !important;
    top: 50%;
    pointer-events: none;
    margin-top: -3.5px;
    border: 7px solid transparent;
    right: 15px; }
  .custom-select-wrapper select {
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    cursor: pointer;
    padding: 0;
    border: none; }
  .custom-select-wrapper .custom-select-value {
    line-height: 33px;
    font-size: 0.85em;
    padding: 0 5px; }

.form-tire-search {
  font-size: 1.1em;
  padding: 10px 10px 20px;
  background: #eee;
  color: #333; }
  @media (min-width: 50em) {
    .form-tire-search {
      padding: 15px;
      max-width: 450px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
      border-radius: 3px; } }
  .form-tire-search h3 {
    text-align: center;
    font-weight: bold;
    margin: 8px 0; }
    @media (min-width: 50em) {
      .form-tire-search h3 {
        display: none; } }
  .form-tire-search label {
    font-weight: bold;
    display: block;
    text-align: center; }
  .form-tire-search input, .form-tire-search select {
    width: 100%; }
  .form-tire-search button {
    background: #05a4a1;
    background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
    color: #FFF;
    border-radius: 3px;
    cursor: pointer;
    width: 100%;
    border: 0 none;
    font-size: 0.9em;
    padding: 13px 10px;
    font-weight: bold; }
    .form-tire-search button:hover {
      background: #05a4a1; }
  .form-tire-search .select-wrapper-width, .form-tire-search .select-wrapper-height {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
  .form-tire-search .select-wrapper-inch {
    width: 32.20339%;
    float: right;
    margin-right: 0; }

.cart-link-wrapper {
  zoom: 1; }
  .cart-link-wrapper:before, .cart-link-wrapper:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .cart-link-wrapper:after {
    clear: both; }
  .cart-link-wrapper a {
    text-decoration: none;
    font-size: 1.2em;
    color: #666;
    margin: 0 0 20px; }
    @media (min-width: 50em) {
      .cart-link-wrapper a {
        float: right; } }
    .cart-link-wrapper a:hover {
      color: #333; }

.button, .shop-cart .button-checkout, .shop-checkout .form-checkout-01 button {
  height: 40px;
  line-height: 40px;
  background: #05a4a1;
  background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
  color: #FFF;
  border-radius: 3px;
  cursor: pointer;
  border: 0 none;
  font-size: 0.9em;
  padding: 0px 15px;
  font-weight: bold;
  width: 100%;
  text-decoration: none; }
  .button:hover, .shop-cart .button-checkout:hover, .shop-checkout .form-checkout-01 button:hover {
    background: #05a4a1; }

.shop-page {
  color: #333;
  padding: 0 15px; }
  @media (min-width: 50em) {
    .shop-page {
      padding: 0; } }
  @media (min-width: 50em) {
    .shop-page aside.sidebar {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 50em) {
    .shop-page main.content {
      width: 66.10169%;
      float: right;
      margin-right: 0; } }

@media (max-width: 50em) {
  .product-list {
    margin: 50px 0; } }
.product-list .product {
  zoom: 1;
  list-style: none;
  border-bottom: 1px solid #ddd;
  font-size: 0.9em; }
  .product-list .product:before, .product-list .product:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .product-list .product:after {
    clear: both; }
  @media (min-width: 64em) {
    .product-list .product {
      font-size: 1em; } }
  .product-list .product:last-child {
    border-bottom: 0 none; }
  .product-list .product a.product-link {
    zoom: 1;
    color: #000;
    text-decoration: none;
    display: block;
    padding: 15px 0; }
    .product-list .product a.product-link:before, .product-list .product a.product-link:after {
      content: "\0020";
      display: block;
      height: 0;
      overflow: hidden; }
    .product-list .product a.product-link:after {
      clear: both; }
    .product-list .product a.product-link .image {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%; }
      .product-list .product a.product-link .image img {
        width: 100%;
        height: auto; }
        @media (min-width: 37.5em) {
          .product-list .product a.product-link .image img {
            max-width: 150px; } }
    .product-list .product a.product-link .description {
      width: 40.67797%;
      float: left;
      margin-right: 1.69492%; }
      .product-list .product a.product-link .description .product-heading {
        font-size: 1em;
        line-height: 1.2em; }
        @media (min-width: 37.5em) {
          .product-list .product a.product-link .description .product-heading {
            font-size: 1.25em; } }
        .product-list .product a.product-link .description .product-heading .primary {
          font-weight: bold; }
    .product-list .product a.product-link .actions {
      zoom: 1;
      width: 32.20339%;
      float: right;
      margin-right: 0;
      text-align: right; }
      .product-list .product a.product-link .actions:before, .product-list .product a.product-link .actions:after {
        content: "\0020";
        display: block;
        height: 0;
        overflow: hidden; }
      .product-list .product a.product-link .actions:after {
        clear: both; }
      .product-list .product a.product-link .actions button {
        height: 30px;
        line-height: 30px;
        background: #05a4a1;
        background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
        color: #FFF;
        border-radius: 3px;
        cursor: pointer;
        border: 0 none;
        font-size: 0.9em;
        padding: 0px 5px;
        font-weight: bold;
        float: right; }
        .product-list .product a.product-link .actions button:hover {
          background: #05a4a1; }
        @media (min-width: 37.5em) {
          .product-list .product a.product-link .actions button {
            float: right;
            padding: 0px 15px;
            height: 40px;
            line-height: 40px; } }
        @media (max-width: 50em) {
          .product-list .product a.product-link .actions button {
            margin: 2px 0;
            width: 75px; } }
      .product-list .product a.product-link .actions button.buy-button {
        margin-left: 10px; }
        .product-list .product a.product-link .actions button.buy-button i {
          font-size: 22px;
          line-height: 30px; }
          @media (min-width: 37.5em) {
            .product-list .product a.product-link .actions button.buy-button i {
              line-height: 40px; } }
      @media (max-width: 50em) {
        .product-list .product a.product-link .actions .price {
          margin: 1%; } }
      .product-list .product a.product-link .actions .price .data {
        font-size: 1.4em;
        font-weight: bold;
        color: #05a4a1; }
        @media (min-width: 37.5em) {
          .product-list .product a.product-link .actions .price .data {
            font-size: 1.75em; } }
      .product-list .product a.product-link .actions .price .price-includes {
        display: none;
        color: #666;
        font-size: 0.8em; }
        @media (min-width: 37.5em) {
          .product-list .product a.product-link .actions .price .price-includes {
            margin: 5px 0 20px;
            display: block; } }

.shop-detail .top {
  zoom: 1;
  margin: 0 0 25px;
  padding: 0 0 25px;
  border-bottom: 1px solid #ddd; }
  .shop-detail .top:before, .shop-detail .top:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .shop-detail .top:after {
    clear: both; }
.shop-detail .bottom {
  zoom: 1;
  color: #666;
  font-size: 0.9em;
  line-height: 1.4em; }
  .shop-detail .bottom:before, .shop-detail .bottom:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .shop-detail .bottom:after {
    clear: both; }
  .shop-detail .bottom .more {
    zoom: 1; }
    .shop-detail .bottom .more:before, .shop-detail .bottom .more:after {
      content: "\0020";
      display: block;
      height: 0;
      overflow: hidden; }
    .shop-detail .bottom .more:after {
      clear: both; }
    @media (min-width: 50em) {
      .shop-detail .bottom .more .left {
        width: 23.72881%;
        float: left;
        margin-right: 1.69492%; } }
    @media (min-width: 50em) {
      .shop-detail .bottom .more .right {
        width: 74.57627%;
        float: right;
        margin-right: 0; } }
    @media (min-width: 50em) {
      .shop-detail .bottom .more img {
        float: left;
        margin: 0 30px 15px 0; } }
    .shop-detail .bottom .more h3 {
      font-weight: bold;
      color: #333;
      font-size: 1.2em;
      margin: 0 0 10px; }
    .shop-detail .bottom .more h4 {
      font-weight: bold;
      color: #333;
      font-size: 1em;
      margin: 0 0 8px; }
    .shop-detail .bottom .more p {
      margin: 0 0 8px; }
    .shop-detail .bottom .more table {
      border: 1px solid #eee;
      margin: 10px 0 20px; }
      .shop-detail .bottom .more table th {
        font-weight: bold; }
@media (min-width: 50em) {
  .shop-detail section.product {
    width: 74.57627%;
    float: left;
    margin-right: 1.69492%; } }
@media (min-width: 50em) {
  .shop-detail section.actions {
    width: 23.72881%;
    float: right;
    margin-right: 0; } }
@media (min-width: 50em) {
  .shop-detail .product .tire-container .product-image {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; } }
.shop-detail .product .tire-container .product-image .tire-thumbnail img {
  max-width: 100%;
  display: block;
  margin: 0 auto; }
.shop-detail .product .tire-container .product-image .product-image-example {
  text-align: center;
  font-size: 0.75em;
  line-height: 1.6em;
  color: #888;
  max-width: 300px;
  margin: 10px auto 0; }
@media (min-width: 50em) {
  .shop-detail .product .tire-container .details {
    width: 49.15254%;
    float: right;
    margin-right: 0; } }
.shop-detail .product .tire-container .details .product-header {
  font-size: 1.5em; }
.shop-detail .product .tire-container .details .specs {
  zoom: 1;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px; }
  .shop-detail .product .tire-container .details .specs:before, .shop-detail .product .tire-container .details .specs:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .shop-detail .product .tire-container .details .specs:after {
    clear: both; }
  .shop-detail .product .tire-container .details .specs ul {
    zoom: 1; }
    .shop-detail .product .tire-container .details .specs ul:before, .shop-detail .product .tire-container .details .specs ul:after {
      content: "\0020";
      display: block;
      height: 0;
      overflow: hidden; }
    .shop-detail .product .tire-container .details .specs ul:after {
      clear: both; }
    .shop-detail .product .tire-container .details .specs ul li {
      list-style: none;
      line-height: 1.2em;
      margin: 10px 0; }
      @media (min-width: 50em) {
        .shop-detail .product .tire-container .details .specs ul li {
          width: 48%;
          float: left; } }
      .shop-detail .product .tire-container .details .specs ul li .label span {
        font-size: 0.8em;
        color: #666; }
      .shop-detail .product .tire-container .details .specs ul li .max-speed {
        font-size: 0.8em; }
.shop-detail .product .tire-container .details ul.info-wrapper {
  zoom: 1; }
  .shop-detail .product .tire-container .details ul.info-wrapper:before, .shop-detail .product .tire-container .details ul.info-wrapper:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .shop-detail .product .tire-container .details ul.info-wrapper:after {
    clear: both; }
  .shop-detail .product .tire-container .details ul.info-wrapper li {
    list-style: none;
    font-size: 0.9em;
    font-weight: bold; }
  @media (min-width: 64em) {
    .shop-detail .product .tire-container .details ul.info-wrapper .left, .shop-detail .product .tire-container .details ul.info-wrapper .right {
      width: 48%;
      float: left; } }
.shop-detail .actions form .row {
  position: relative; }
.shop-detail .actions form .row.qty .custom-select-wrapper {
  width: 60px;
  position: absolute; }
  .shop-detail .actions form .row.qty .custom-select-wrapper select {
    width: auto; }
.shop-detail .actions form .row.qty .price {
  margin: 10px 0 0 75px; }
  .shop-detail .actions form .row.qty .price .x {
    float: left;
    margin-right: 15px; }
  .shop-detail .actions form .row.qty .price .note {
    position: relative;
    left: -75px;
    font-size: 0.9em;
    color: #666;
    top: 10px; }
  .shop-detail .actions form .row.qty .price .current {
    font-weight: bold; }
.shop-detail .actions form .uvp-cart .services {
  padding: 20px 0; }
  .shop-detail .actions form .uvp-cart .services li {
    list-style: none;
    font-size: 0.9em;
    line-height: 1.5em; }
.shop-detail .actions form .in-stock-wrapper {
  margin: 0 0 20px; }
  .shop-detail .actions form .in-stock-wrapper ul.stock {
    margin: 0;
    padding: 0;
    display: inline; }
    .shop-detail .actions form .in-stock-wrapper ul.stock li {
      list-style: none;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: #eee;
      border: 1px solid #ccc;
      display: inline-block;
      margin-right: 3px; }
      .shop-detail .actions form .in-stock-wrapper ul.stock li.green {
        background: #00bd00;
        border: 1px solid #00bd00; }
      .shop-detail .actions form .in-stock-wrapper ul.stock li.yellow {
        background: #ffff00;
        border: 1px solid #ffff00; }
      .shop-detail .actions form .in-stock-wrapper ul.stock li.red {
        background: #d10000;
        border: 1px solid #d10000; }
.shop-detail .actions form .row.buttons button {
  height: 40px;
  line-height: 40px;
  background: #05a4a1;
  background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
  color: #FFF;
  border-radius: 3px;
  cursor: pointer;
  border: 0 none;
  font-size: 0.9em;
  padding: 0px 15px;
  font-weight: bold;
  width: 100%; }
  .shop-detail .actions form .row.buttons button:hover {
    background: #05a4a1; }

.label-wrapper li {
  list-style: none; }
  @media (min-width: 37.5em) {
    .label-wrapper li {
      margin-top: 15px; } }
  .label-wrapper li.euLabel .infos {
    color: #666;
    line-height: 1.4em;
    font-size: 0.9em; }
  .label-wrapper li .label {
    font-size: 0.8em;
    color: #666; }
  .label-wrapper li span.icon {
    display: inline-block;
    height: 14px;
    width: 14px;
    background: url('http://cdn.tirstatic.net/images/icons/eu-label.png?1456998332') 0 0 no-repeat; }
  .label-wrapper li span.eu-label-fuel-class {
    background-position: 0 -14px; }
  .label-wrapper li span.eu-label-grip-class {
    background-position: 0 0; }
  .label-wrapper li span.icon.eu-label-noise-db {
    background-position: 0 -28px; }
  .label-wrapper li span + span {
    margin-left: 5px; }

.pagination {
  zoom: 1;
  font-size: 0.85em;
  color: #666; }
  .pagination:before, .pagination:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .pagination:after {
    clear: both; }
  .pagination li {
    list-style: none;
    float: left; }
    .pagination li .active, .pagination li a {
      display: block;
      padding: 0 8px;
      margin: 5px; }
    .pagination li a {
      text-decoration: none;
      color: #666;
      background: #f5f5f5;
      border: 1px solid #ddd; }
      .pagination li a:hover {
        background: #eee;
        color: #333; }

.shop-cart h2 {
  font-size: 1.5em; }
  @media (min-width: 37.5em) {
    .shop-cart h2 {
      float: left;
      margin: 20px 0 0; } }
.shop-cart .top {
  zoom: 1; }
  .shop-cart .top:before, .shop-cart .top:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .shop-cart .top:after {
    clear: both; }
.shop-cart .cart-wrapper {
  border: 1px solid #ddd;
  margin: 10px 0; }
  .shop-cart .cart-wrapper header {
    background: #eee;
    display: none;
    zoom: 1; }
    @media (min-width: 37.5em) {
      .shop-cart .cart-wrapper header {
        display: block; } }
    .shop-cart .cart-wrapper header:before, .shop-cart .cart-wrapper header:after {
      content: "\0020";
      display: block;
      height: 0;
      overflow: hidden; }
    .shop-cart .cart-wrapper header:after {
      clear: both; }
    .shop-cart .cart-wrapper header .label {
      padding: 5px 10px;
      color: #888;
      font-size: 0.85em; }
      @media (min-width: 37.5em) {
        .shop-cart .cart-wrapper header .label.label-product {
          width: 49.15254%;
          float: left;
          margin-right: 1.69492%; } }
      @media (min-width: 37.5em) {
        .shop-cart .cart-wrapper header .label.label-qty {
          width: 15.25424%;
          float: left;
          margin-right: 1.69492%; } }
      @media (min-width: 37.5em) {
        .shop-cart .cart-wrapper header .label.label-price {
          width: 15.25424%;
          float: left;
          margin-right: 1.69492%;
          text-align: center; } }
      @media (min-width: 37.5em) {
        .shop-cart .cart-wrapper header .label.label-total-price {
          width: 15.25424%;
          float: right;
          margin-right: 0;
          text-align: right; } }
  .shop-cart .cart-wrapper main .alert {
    display: block;
    padding: 5px;
    border-radius: 3px;
    margin: 10px;
    color: #333; }
    .shop-cart .cart-wrapper main .alert.alert-danger {
      background: #FFBABA;
      color: #D8000C; }
  .shop-cart .cart-wrapper main ul.product-list li.product {
    padding: 15px 0;
    border-bottom: 1px solid #ddd; }
    .shop-cart .cart-wrapper main ul.product-list li.product:last-child {
      border-bottom: 0 none; }
    @media (min-width: 37.5em) {
      .shop-cart .cart-wrapper main ul.product-list li.product .col-product {
        width: 49.15254%;
        float: left;
        margin-right: 1.69492%; } }
    .shop-cart .cart-wrapper main ul.product-list li.product .col-product .image {
      padding: 5px 10px; }
      @media (min-width: 37.5em) {
        .shop-cart .cart-wrapper main ul.product-list li.product .col-product .image {
          width: 32.20339%;
          float: left;
          margin-right: 1.69492%; } }
      .shop-cart .cart-wrapper main ul.product-list li.product .col-product .image img {
        width: 100%;
        height: auto; }
    .shop-cart .cart-wrapper main ul.product-list li.product .col-product .description {
      padding: 5px 10px; }
      @media (min-width: 37.5em) {
        .shop-cart .cart-wrapper main ul.product-list li.product .col-product .description {
          width: 66.10169%;
          float: right;
          margin-right: 0; } }
      .shop-cart .cart-wrapper main ul.product-list li.product .col-product .description .product-heading {
        font-size: 1em;
        line-height: 1.2em; }
        @media (min-width: 37.5em) {
          .shop-cart .cart-wrapper main ul.product-list li.product .col-product .description .product-heading {
            font-size: 1.25em; } }
        .shop-cart .cart-wrapper main ul.product-list li.product .col-product .description .product-heading .primary {
          font-weight: bold; }
      .shop-cart .cart-wrapper main ul.product-list li.product .col-product .description .services {
        padding: 5px 0;
        color: #666; }
        .shop-cart .cart-wrapper main ul.product-list li.product .col-product .description .services li {
          list-style: none;
          font-size: 0.9em;
          line-height: 1.4em; }
    .shop-cart .cart-wrapper main ul.product-list li.product .col-qty {
      padding: 5px 10px; }
      @media (min-width: 37.5em) {
        .shop-cart .cart-wrapper main ul.product-list li.product .col-qty {
          width: 15.25424%;
          float: left;
          margin-right: 1.69492%; } }
      .shop-cart .cart-wrapper main ul.product-list li.product .col-qty a {
        color: #666; }
        .shop-cart .cart-wrapper main ul.product-list li.product .col-qty a:hover {
          color: #333; }
    .shop-cart .cart-wrapper main ul.product-list li.product .col-price {
      padding: 5px 10px;
      font-weight: bold;
      font-size: 1.25em; }
      @media (min-width: 37.5em) {
        .shop-cart .cart-wrapper main ul.product-list li.product .col-price {
          width: 15.25424%;
          float: left;
          margin-right: 1.69492%;
          text-align: center; } }
      .shop-cart .cart-wrapper main ul.product-list li.product .col-price .label-mobile {
        font-weight: normal; }
        @media (min-width: 37.5em) {
          .shop-cart .cart-wrapper main ul.product-list li.product .col-price .label-mobile {
            display: none; } }
    .shop-cart .cart-wrapper main ul.product-list li.product .col-total-price {
      padding: 5px 10px;
      font-weight: bold;
      font-size: 1.25em; }
      @media (min-width: 37.5em) {
        .shop-cart .cart-wrapper main ul.product-list li.product .col-total-price {
          width: 15.25424%;
          float: right;
          margin-right: 0;
          text-align: right; } }
      .shop-cart .cart-wrapper main ul.product-list li.product .col-total-price .label-mobile {
        font-weight: normal; }
        @media (min-width: 37.5em) {
          .shop-cart .cart-wrapper main ul.product-list li.product .col-total-price .label-mobile {
            display: none; } }
  .shop-cart .cart-wrapper footer {
    background: #eee;
    padding: 10px; }
    @media (min-width: 37.5em) {
      .shop-cart .cart-wrapper footer {
        text-align: right; } }
    .shop-cart .cart-wrapper footer .number {
      font-weight: bold;
      font-size: 1.25em; }
.shop-cart .button-checkout {
  display: inline-block;
  width: auto;
  margin: 10px 0; }
  @media (min-width: 37.5em) {
    .shop-cart .button-checkout {
      float: right; } }

.shop-checkout h2 {
  font-size: 1.5em; }
  @media (min-width: 37.5em) {
    .shop-checkout h2 {
      float: left;
      margin: 20px 0 0; } }
@media (min-width: 37.5em) {
  .shop-checkout main {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%; } }
.shop-checkout aside {
  background: #eee;
  padding: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 3px; }
  @media (min-width: 37.5em) {
    .shop-checkout aside {
      width: 32.20339%;
      float: right;
      margin-right: 0; } }
  .shop-checkout aside h3 {
    font-size: 1.25em; }
  .shop-checkout aside a {
    color: #666; }
    .shop-checkout aside a:hover {
      color: #333; }
  .shop-checkout aside .products {
    margin: 10px 0; }
    .shop-checkout aside .products .item {
      list-style: none;
      padding: 15px 0;
      padding: 10px;
      border: 1px solid #d8d8d8;
      margin-top: -1px; }
      .shop-checkout aside .products .item:nth-child(odd) {
        background: #fefefe; }
      .shop-checkout aside .products .item h4 {
        font-weight: bold; }
      .shop-checkout aside .products .item p {
        color: #666; }
      .shop-checkout aside .products .item .price {
        font-weight: bold;
        font-size: 1.2em; }
  .shop-checkout aside .number {
    font-weight: bold;
    font-size: 1.25em; }
.shop-checkout .top {
  zoom: 1; }
  .shop-checkout .top:before, .shop-checkout .top:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .shop-checkout .top:after {
    clear: both; }
.shop-checkout .row-salutation {
  margin: 20px 0; }
  .shop-checkout .row-salutation label {
    margin-right: 10px; }
.shop-checkout .form-checkout-01 button {
  display: inline-block;
  width: auto;
  margin: 10px 0; }
  @media (min-width: 37.5em) {
    .shop-checkout .form-checkout-01 button {
      float: right; } }

