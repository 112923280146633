* {
	box-sizing: border-box;
	outline: 0 none;
}

body {
	font: normal 16px/1.6em sans-serif;
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	overflow-y: scroll;
}

.wrapper {
	max-width: 1140px;
	margin: 0 auto;
	padding: 0 15px;
}

.page-wrapper {
	max-width: 1140px;
	margin: 100px auto;
	padding: 0 15px;
}

.container {
	@include container;
	/* height: 100vh; */
}

.img-responsive {
	max-width: 100%;
	height: auto;
}

.row {
	@include clearfix;
	.col-6 {
		@include breakpoint(laptop) {
			@include span(6);
		}
		&:last-child {
			@include breakpoint(laptop) {
				@include span(6 last);
			}
		}
		&.last-child {
			@include breakpoint(laptop) {
				@include span(6 last);
			}
		}
	}
}