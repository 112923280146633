.cart-link-wrapper {
	@include clearfix;
	a {
		text-decoration: none;
		font-size: 1.2em;
		color: #666;
		margin: 0 0 20px;
		@include breakpoint(tablet){
			float: right;
		}
		&:hover {
			color: #333;
		}
	}
}

.button {
	height: 40px;
	line-height: 40px;
	background: #05a4a1;
	background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
	color: #FFF;
	border-radius: 3px;
	cursor: pointer;
	border: 0 none;
	font-size: 0.9em;
	padding: 0px 15px;
	font-weight: bold;
	width: 100%;
	text-decoration: none;
	&:hover {
		background: #05a4a1;
	}
}

.shop-page {
	color: #333;
  	padding: 0 15px;
  	@include breakpoint(tablet){
		padding: 0;
	}
	aside.sidebar {
		@include breakpoint(tablet){
			@include span(4);
		}
	}
	main.content {
		@include breakpoint(tablet){
			@include span(8 last);
		}
	}
}

.product-list {
	@include breakpoint(mobileonly){
		margin: 50px 0;
	}
	.product {
		@include clearfix;
		list-style: none;
		border-bottom: 1px solid #ddd;
		font-size: 0.9em;
		@include breakpoint(laptop){
			font-size: 1em;
		}
		&:last-child {
			border-bottom: 0 none;
		}
		a.product-link {
			@include clearfix;
			color: #000;
			text-decoration: none;
			display: block;
			padding: 15px 0;
			.image {
				//@include breakpoint(phablet){
					@include span(3);
				//}
				img {
					width: 100%;
					height: auto;
					@include breakpoint(phablet){
						max-width: 150px;
					}
				}
			}
			.description {
				//@include breakpoint(phablet){
					@include span(5);
				//}
				.product-heading {
					font-size: 1em;
					line-height: 1.2em;
					@include breakpoint(phablet){
						font-size: 1.25em;
					}
					.primary {
						font-weight: bold;
					}
					.secondary {

					}
				}
				.product-sub-heading {

				}
			}
			.actions {
				@include clearfix;
				//@include breakpoint(phablet){
					@include span(4 last);
					text-align: right;
				//}
				button {
					height: 30px;
					line-height: 30px;
					background: #05a4a1;
					background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
					color: #FFF;
					border-radius: 3px;
					cursor: pointer;
					border: 0 none;
					font-size: 0.9em;
					padding: 0px 5px;
					font-weight: bold;
					float: right;
					&:hover {
						background: #05a4a1;
					}
					@include breakpoint(phablet){
						float: right;
						padding: 0px 15px;
						height: 40px;
						line-height: 40px;
					}
					@include breakpoint(mobileonly){
						margin: 2px 0;
						width: 75px;
					}
				}
				button.show-details {

				}
				button.buy-button {
					margin-left: 10px;
					i {
						font-size: 22px;
						line-height: 30px;
						@include breakpoint(phablet){
							line-height: 40px;
						}
					}
				}
				.price {
					@include breakpoint(mobileonly){
						margin: 1%;
					}
					.data {
						font-size: 1.4em;
						font-weight: bold;
						color: #05a4a1;
						@include breakpoint(phablet){
							font-size: 1.75em;
						}
					}
					.price-includes {
						display: none;
						color: #666;
						font-size: 0.8em;
						@include breakpoint(phablet){
							margin: 5px 0 20px;
							display: block;
						}
					}
				}
			}
		}
	}
}

.shop-detail {
	.top {
		@include clearfix;
		margin: 0 0 25px;
		padding: 0 0 25px;
		border-bottom: 1px solid #ddd;
	}
	.bottom {
		@include clearfix;
		color: #666;
		font-size: 0.9em;
		line-height: 1.4em;
		.more {
			@include clearfix;
			.left {
				@include breakpoint(tablet){
					@include span(3);
				}
			}
			.right {
				@include breakpoint(tablet){
					@include span(9 last);
				}
			}
			img {
				@include breakpoint(tablet){
					float: left;
					margin: 0 30px 15px 0;
				}
			}
			h3 {
				font-weight: bold;
				color: #333;
				font-size: 1.2em;
				margin: 0 0 10px;
			}
			h4 {
				font-weight: bold;
				color: #333;
				font-size: 1em;
				margin: 0 0 8px;
			}
			p {
				margin: 0 0 8px;
			}
			table {
				border: 1px solid #eee;
				margin: 10px 0 20px;
				th {
					font-weight: bold;
				}
			}
		}
	}
	section.product {
		@include breakpoint(tablet){
			@include span(9);
		}
	}
	section.actions {
		@include breakpoint(tablet){
			@include span(3 last);
		}
	}
	.product {
		.tire-container {
			.product-image {
				@include breakpoint(tablet){
					@include span(6);
				}
				.tire-thumbnail {
					img {
						max-width: 100%;
						display: block;
						margin: 0 auto;
					}
				}
				.product-image-example {
					text-align: center;
					font-size: 0.75em;
					line-height: 1.6em;
					color: #888;
					max-width: 300px;
					margin: 10px auto 0;
				}
			}
			.details {
				@include breakpoint(tablet){
					@include span(6 last);
				}
				.product-header {
					font-size: 1.5em;
					span {
						strong {

						}
					}
				}
				.specs {
					@include clearfix;
					border-bottom: 1px solid #ddd;
					padding-bottom: 10px;
					margin-bottom: 10px;
					ul {
						@include clearfix;
						li {
							list-style: none;
							line-height: 1.2em;
							margin: 10px 0;
							@include breakpoint(tablet){
								width: 48%;
								float: left;
							}
							.label {
								span {
									font-size: 0.8em;
									color: #666;
								}
							}
							.max-speed {
								font-size: 0.8em;
							}
							.usage {
								&.usage-summer {

								}
							}
						}
					}
				}
				ul.info-wrapper {
					@include clearfix;
					li {
						list-style: none;
						font-size: 0.9em;
						font-weight: bold;
					}
					.left,
					.right {
						@include breakpoint(laptop){
							width: 48%;
							float: left;
						}
					}
				}
			}
		}
	}
	.actions {
		form {
			.row {
				position: relative;
			}
			.row.sku {

			}
			.row.qty {
				.custom-select-wrapper {
					width: 60px;
					position: absolute;
					select {
						width: auto;
					}
				}
				.price {
					margin: 10px 0 0 75px;
					.x {
						float: left;
						margin-right: 15px;
					}
					.note {
						position: relative;
						left: -75px;
						font-size: 0.9em;
						color: #666;
						top: 10px;
					}
					.current {
						font-weight: bold;
					}
				}
			}
			.uvp-cart {
				.services {
					padding: 20px 0;
					li {
						list-style: none;
						font-size: 0.9em;
						line-height: 1.5em;
						&.shipping {

						}
					}
				}
			}
			.in-stock-wrapper {
				margin: 0 0 20px;
				ul.stock {
					margin: 0;
					padding: 0;
					display: inline;
					li {
						list-style: none;
						width: 12px;
						height: 12px;
						border-radius: 100%;
						background: #eee;
						border: 1px solid #ccc;
						display: inline-block;
						margin-right: 3px;
						&.green {
							background: #00bd00;
							border: 1px solid #00bd00;
						}
						&.yellow {
							background: #ffff00;
							border: 1px solid #ffff00;
						}
						&.red {
							background: #d10000;
							border: 1px solid #d10000;
						}
					}
				}
			}
			.row.buttons {
				button {
					height: 40px;
					line-height: 40px;
					background: #05a4a1;
					background-image: linear-gradient(to bottom, #05a4a1 0%, #107271 100%);
					color: #FFF;
					border-radius: 3px;
					cursor: pointer;
					border: 0 none;
					font-size: 0.9em;
					padding: 0px 15px;
					font-weight: bold;
					width: 100%;
					&:hover {
						background: #05a4a1;
					}
				}
			}
		}
	}
}

.label-wrapper {
	li {
		list-style: none;
		@include breakpoint(phablet){
			margin-top: 15px;
		}
		&.euLabel {
			.infos {
				color: #666;
				line-height: 1.4em;
				font-size: 0.9em;
			}
		}
		.label {
    		font-size: 0.8em;
    		color: #666;
		}
		span {
			&.icon {
				display: inline-block;
				height: 14px;
				width: 14px;
				background: url('http://cdn.tirstatic.net/images/icons/eu-label.png?1456998332') 0 0 no-repeat;
			}
			&.eu-label-fuel-class {
				background-position: 0 -14px;
			}
			&.eu-label-grip-class {
				background-position: 0 0;
			}
			&.icon.eu-label-noise-db {
				background-position: 0 -28px;
			}
		}
		span + span {
			margin-left: 5px;
		}
		.h5 {

		}
	}
}

.pagination {
	@include clearfix;
	font-size: 0.85em;
	color: #666;
	li {
		list-style: none;
		float: left;
		.active,
		a {
			display: block;
			padding: 0 8px;
			margin: 5px;
		}
		.active {

		}
		a {
			text-decoration: none;
			color: #666;
			background: #f5f5f5;
			border: 1px solid #ddd;
			&:hover {
				background: #eee;
				color: #333;
			}
		}
	}
}

.shop-cart {
	h2 {
		font-size: 1.5em;
		@include breakpoint(phablet){
			float: left;
			margin: 20px 0 0;
		}
	}
	.top {
		@include clearfix;
	}
	.cart-wrapper {
		border: 1px solid #ddd;
		margin: 10px 0;
		header {
			background: #eee;
			display: none;
			@include breakpoint(phablet){
				display: block;
			}
			@include clearfix;
			.label {
				padding: 5px 10px;
				color: #888;
				font-size: 0.85em;
				&.label-product {
					@include breakpoint(phablet){
						@include span(6);
					}
				}
				&.label-qty {
					@include breakpoint(phablet){
						@include span(2);
					}
				}
				&.label-price {
					@include breakpoint(phablet){
						@include span(2);
						text-align: center;
					}
				}
				&.label-total-price {
					@include breakpoint(phablet){
						@include span(2 last);
						text-align: right;
					}
				}
			}
		}
		main {
			.alert {
				display: block;
				padding: 5px;
				border-radius: 3px;
				margin: 10px;
				color: #333;
				&.alert-danger {
					background: #FFBABA;
					color: #D8000C;
				}
			}
			ul.product-list {
				li.product {
					padding: 15px 0;
					border-bottom: 1px solid #ddd;
					&:last-child {
						border-bottom: 0 none;
					}
					.col-product {
						@include breakpoint(phablet){
							@include span(6);
						}
						.image {
							padding: 5px 10px;
							@include breakpoint(phablet){
								@include span(4);
							}
							img {
								width: 100%;
								height: auto;
							}
						}
						.description {
							padding: 5px 10px;
							@include breakpoint(phablet){
								@include span(8 last);
							}
							.product-heading {
								font-size: 1em;
								line-height: 1.2em;
								@include breakpoint(phablet){
									font-size: 1.25em;
								}
								.primary {
									font-weight: bold;
								}
								.secondary {

								}
							}
							.product-sub-heading {

							}
							.services {
								padding: 5px 0;
								color: #666;
								li {
									list-style: none;
									font-size: 0.9em;
									line-height: 1.4em;
									&.shipping {

									}
								}
							}
						}
					}
					.col-qty {
						padding: 5px 10px;
						@include breakpoint(phablet){
							@include span(2);
						}
						a {
							color: #666;
							&:hover {
								color: #333;
							}
						}
					}
					.col-price {
						padding: 5px 10px;
						font-weight: bold;
						font-size: 1.25em;
						@include breakpoint(phablet){
							@include span(2);
							text-align: center;
						}
						.label-mobile {
							font-weight: normal;
							@include breakpoint(phablet){
								display: none;
							}
						}
					}
					.col-total-price {
						padding: 5px 10px;
						font-weight: bold;
						font-size: 1.25em;
						@include breakpoint(phablet){
							@include span(2 last);
							text-align: right;
						}
						.label-mobile {
							font-weight: normal;
							@include breakpoint(phablet){
								display: none;
							}
						}
					}
				}
			}
		}
		footer {
			background: #eee;
			padding: 10px;
			@include breakpoint(phablet){
				text-align: right;
			}
			.number {
				font-weight: bold;
				font-size: 1.25em;
			}
		}
	}
	.button-checkout {
		@extend .button;
		display: inline-block;
		width: auto;
		margin: 10px 0;
		@include breakpoint(phablet){
			float: right;
		}
	}
}

.shop-checkout {
	h2 {
		font-size: 1.5em;
		@include breakpoint(phablet){
			float: left;
			margin: 20px 0 0;
		}
	}
	main {
		@include breakpoint(phablet){
			@include span(8);
		}
	}
	aside {
		@include breakpoint(phablet){
			@include span(4 last);
		}
		background: #eee;
		padding: 25px;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    	border-radius: 3px;
		h3 {
			font-size: 1.25em;
		}
		a {
			color: #666;
			&:hover {
				color: #333;
			}
		}
		.products {
			margin: 10px 0;
			.item {
				list-style: none;
				padding: 15px 0;
				padding: 10px;
				border: 1px solid #d8d8d8;
				margin-top: -1px;
				&:nth-child(odd) {
   					background: #fefefe;
				}
				h4 {
					font-weight: bold;
				}
				p {
					color: #666;
				}
				.price {
					font-weight: bold;
					font-size: 1.2em;
				}
			}
		}
		.number {
    		font-weight: bold;
    		font-size: 1.25em;
		}
	}
	.top {
		@include clearfix;
	}
	.row-salutation {
		margin: 20px 0;
		label {
			margin-right: 10px;
		}
	}
	.form-checkout-01 {
		button {
			@extend .button;
			display: inline-block;
			width: auto;
			margin: 10px 0;
			@include breakpoint(phablet){
				float: right;
			}
		}
	}
}