table { 
	width: 100%; 
	border-collapse: collapse; 
}

tr:nth-of-type(odd) { 
	background: #eee; 
}

th { 
	background: #333; 
	color: white; 
	font-weight: bold; 
}

td, th { 
	padding: 6px; 
	border: 1px solid #ccc; 
	text-align: left; 
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table.responsive, .responsive thead, .responsive tbody, .responsive th, .responsive td, .responsive tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	.responsive thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	.responsive tr { border: 1px solid #ccc; }
	
	.responsive td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	.responsive td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	.responsive td:before { content: attr(data-table-header); }
}

.table-responsive .bootstrap {
	min-height: .01%;
	overflow-x: auto;
}
@media screen and (max-width: 767px) {
	.table-responsive.bootstrap {
		width: 100%;
		margin-bottom: 15px;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid #ddd;
	}
	.table-responsive.bootstrap > .table {
		margin-bottom: 0;
	}
	.table-responsive.bootstrap > .table > thead > tr > th,
	.table-responsive.bootstrap > .table > tbody > tr > th,
	.table-responsive.bootstrap > .table > tfoot > tr > th,
	.table-responsive.bootstrap > .table > thead > tr > td,
	.table-responsive.bootstrap > .table > tbody > tr > td,
	.table-responsive.bootstrap > .table > tfoot > tr > td {
		white-space: nowrap;
	}
	.table-responsive.bootstrap > .table-bordered {
		border: 0;
	}
	.table-responsive.bootstrap > .table-bordered > thead > tr > th:first-child,
	.table-responsive.bootstrap > .table-bordered > tbody > tr > th:first-child,
	.table-responsive.bootstrap > .table-bordered > tfoot > tr > th:first-child,
	.table-responsive.bootstrap > .table-bordered > thead > tr > td:first-child,
	.table-responsive.bootstrap > .table-bordered > tbody > tr > td:first-child,
	.table-responsive.bootstrap > .table-bordered > tfoot > tr > td:first-child {
		border-left: 0;
	}
	.table-responsive.bootstrap > .table-bordered > thead > tr > th:last-child,
	.table-responsive.bootstrap > .table-bordered > tbody > tr > th:last-child,
	.table-responsive.bootstrap > .table-bordered > tfoot > tr > th:last-child,
	.table-responsive.bootstrap > .table-bordered > thead > tr > td:last-child,
	.table-responsive.bootstrap > .table-bordered > tbody > tr > td:last-child,
	.table-responsive.bootstrap > .table-bordered > tfoot > tr > td:last-child {
		border-right: 0;
	}
	.table-responsive.bootstrap > .table-bordered > tbody > tr:last-child > th,
	.table-responsive.bootstrap > .table-bordered > tfoot > tr:last-child > th,
	.table-responsive.bootstrap > .table-bordered > tbody > tr:last-child > td,
	.table-responsive.bootstrap > .table-bordered > tfoot > tr:last-child > td {
		border-bottom: 0;
	}
}